/* eslint no-underscore-dangle: 0 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import React from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpg";

// Firebase authentication
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

// @mui material lab components
import EmailVerify from "layouts/authentication/verifyEmailSuccess";

// Axios
import Axios from "axios";

function Cover() {
  const API_URL = process.env.REACT_APP_API_URL;

  const auth = getAuth();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [emailFieldIsValid, setEmailFieldIsValid] = React.useState(true);
  const [emailHelperText, setEmailHelperText] = React.useState("");
  const [passwordFieldIsValid, setPasswordFieldIsValid] = React.useState(true);
  const [passwordHelperText, setPasswordHelperText] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);

  const validateForm = (emailStr, passwordStr) => {
    let result = true;

    if (emailStr === "") {
      setEmailHelperText("Email is required");
      setEmailFieldIsValid(false);
      result = false;
    } else if (!emailStr.includes("@")) {
      setEmailHelperText("Email is invalid");
      setEmailFieldIsValid(false);
      result = false;
    } else {
      setEmailHelperText("");
      setEmailFieldIsValid(true);
    }

    if (passwordStr === "") {
      setPasswordHelperText("Password is required");
      setPasswordFieldIsValid(false);
      result = false;
    } else if (passwordStr.length < 6) {
      setPasswordHelperText("Password must be at least 6 characters");
      setPasswordFieldIsValid(false);
      result = false;
    } else {
      setPasswordHelperText("");
      setPasswordFieldIsValid(true);
    }
    return result;
  };

  const createUserHandler = () => {
    const url = "Auth/AddUser";
    const Bearer = "Bearer ";
    const isValidate = validateForm(email, password);

    if (!isValidate) return;

    if (!checked) {
      setError("You must agree to the terms and conditions");
      return;
    }

    setLoading(true);

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        sendEmailVerification(user)
          .then(() => {
            Axios.post(
              API_URL + url,
              {
                ProviderId: userCredential.providerId,
                Uid: userCredential.user.uid,
                Email: userCredential.user.email,
              },
              {
                headers: { Authorization: Bearer + userCredential._tokenResponse.idToken },
              }
            )
              .then(() => {
                setIsSuccess(true);
              })
              .catch((exError) => {
                if (exError.response && exError.response.status === 400) {
                  exError.response.data.message.map((p) => {
                    setError(p);
                    return p;
                  });
                } else {
                  setError("Failed to connect to server");
                }
                setLoading(false);
              });
          })
          .catch((exError) => {
            const errorCode = exError.code;
            const errorTitle = "Send Email Verification: ";
            setError(errorTitle + errorCode);
          });
      })
      .catch((exError) => {
        const errorCode = exError.code;
        setError(errorCode);
        setLoading(false);
      });
  };

  const onCheckedChange = (event) => setChecked(event.target.checked);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    validateForm(event.target.value, password);
  };

  const onPasswordChange = (event) => {
    validateForm(email, event.target.value);
    setPassword(event.target.value);
  };

  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );

  return isSuccess ? (
    <EmailVerify />
  ) : (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join {process.env.REACT_APP_WEBSITE_TITLE} Today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {error && <MDAlert color="warning">{alertContent(error)}</MDAlert>}

            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                required
                error={!emailFieldIsValid}
                variant="standard"
                fullWidth
                helperText={emailHelperText}
                onChange={onEmailChange}
                value={email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                error={!passwordFieldIsValid}
                required
                fullWidth
                onChange={onPasswordChange}
                value={password}
                helperText={passwordHelperText}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked={checked} onChange={onCheckedChange} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href={process.env.REACT_APP_TERM_AND_CONDITION_URL}
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {loading ? (
                <MDButton variant="outlined" size="large" fullWidth disabled>
                  loading...
                </MDButton>
              ) : (
                <MDButton variant="gradient" color="info" fullWidth onClick={createUserHandler}>
                  Sign Up
                </MDButton>
              )}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
