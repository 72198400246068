/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https:www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https:www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import React from "react";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// EmptyLayout components
import EmptyLayout from "layouts/authentication/components/EmptyLayout";

// Firebase authentication
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

function Cover() {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);

  return (
    <EmptyLayout>
      {loading && (
        <div className="loading">
          <div className="loader" />
        </div>
      )}

      {error && <MDAlert color="error">Error: {error}</MDAlert>}

      <MDAlert color="success">
        <MDTypography variant="body2" color="white">
          We&apos;ll send an email to {user.email} in 5 minutes. Open it up to activate your
          account. (if you can&apos;t find it in your inbox, Please check your SPAM or junk mail
          folder)
        </MDTypography>
      </MDAlert>
    </EmptyLayout>
  );
}

export default Cover;
