/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";

function DataTablePrintCell({ url }) {
  return (
    <MDBox
      component="td"
      textAlign="center"
      py={1.5}
      px={3}
      sx={({ typography: { size } }) => ({
        fontSize: size.sm,
        borderBottom: "none",
      })}
    >
      <MDButton
        component="a"
        href={url}
        target="_blank"
        rel="noreferrer"
        variant="text"
        color="dark"
      >
        <Icon>print</Icon>&nbsp;print
      </MDButton>

      {/* <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        <a className="k-button k-flat k-button-icontext" href={url} target='_blank' >
          <span role="presentation" class="k-icon k-i-print"></span>Print
        </a>
      </MDBox> */}
    </MDBox>
  );
}

// Setting default values for the props of DataTablePrintCell
DataTablePrintCell.defaultProps = {
  url: "#"
};

// Typechecking props for the DataTablePrintCell
DataTablePrintCell.propTypes = {
  url: PropTypes.string.isRequired
};

export default DataTablePrintCell;
